<template>
  <SidePopup
    title="Detalhes de pagamento"
    v-on:close="onClose"
    routeback="/fornecedor/pagamentos"
  >
    <v-row>
      <v-col sm="12">
        <div class="pay md">
          <div class="pay-label">Numero do pedido</div>
          <div class="pay-value">{{ numpedido }}</div>
        </div>
      </v-col>
      <v-col sm="12">
        <div class="pay md">
          <div class="pay-label">Id pagamento</div>
          <div class="pay-value">{{ idpagamento }}</div>
        </div>
      </v-col>
      <v-col sm="4">
        <div class="pay md">
          <div class="pay-label">Data do pagamento</div>
          <div class="pay-value">{{ data_pagamento }}</div>
        </div>
      </v-col>
      <v-col sm="4">
        <div class="pay">
          <div class="pay-label">Status do pedido</div>
          <div class="pay-value">{{ statuspedido }}</div>
        </div>
      </v-col>
      <v-col sm="4">
        <div class="pay">
          <div class="pay-label">Nome do cliente</div>
          <div class="pay-value">{{ nome_cliente }}</div>
        </div>
      </v-col>
      <v-col sm="4">
        <div class="pay">
          <div class="pay-label">Total do pedido</div>
          <div class="pay-value">R$ {{ total_pedido }}</div>
        </div>
      </v-col>
      <v-col sm="4">
        <div class="pay">
          <div class="pay-label">Total pago</div>
          <div class="pay-value">R$ {{ total_pago }}</div>
        </div>
      </v-col>
      <v-col sm="4">
        <div class="pay">
          <div class="pay-label">Total estornado</div>
          <div class="pay-value">R$ {{ total_estornado }}</div>
        </div>
      </v-col>
      <v-col sm="4">
        <div class="pay">
          <div class="pay-label">Valor a ser estornado</div>
          <div class="pay-value">R$ {{ total_para_estorno }}</div>
        </div>
      </v-col>

      <v-col sm="12">
        <div class="pay">
          <div class="pay-label">Logs</div>
        </div>
        <table class="table">
          <thead>
            <th>Data</th>
            <th>Ação</th>
            <th>Valor</th>
            <th>Status</th>
            <th>Tid/Sequencial</th>
            <th>Bandeira</th>
            <th>Mensagem</th>
          </thead>
          <tbody>
            <tr v-for="(log, index) in logs" :key="'log-' + index">
              <td class="date">{{ log.date }}</td>
              <td class="alias">{{ log.AcaoAlias }}</td>
              <td class="valor">R$ {{ log.ValorTransacao }}</td>
              <td class="status">{{ log.Sucesso ? 'Sucesso' : 'falha' }}</td>
              <td class="tid">{{ log.Tid }} | {{ log.Sequential }}</td>
              <td class="bandeira">{{ log.bandeira }}</td>
              <td class="message">{{ log.Mensagem }}</td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </SidePopup>
</template>

<script>
import { PAGAMENTO_DETALHE } from '@/store/actions/pagamento'
import SidePopup from '@/components/SidePopup'
import * as _ from 'lodash'
import moment from 'moment'

export default {
  name: 'DetalhePagamento',
  components: { SidePopup },
  data: () => ({
    numpedido: '',
    idpagamento: '',
    data_pagamento: '',
    statuspedido: '',
    nome_cliente: '',
    total_pedido: 0,
    total_pago: 0,
    total_estornado: 0,
    total_para_estorno: 0,
    logs: [],
    statusLabel: {
      ORCAMENTO_NOVO: 'Orçamento novo',
      ORCAMENTO_PRECIFICADO: 'Orçamento precificado',
      ENVIADO: 'Novo pedido',
      SEPARANDO: 'Em separação',
      AGUARDANDOPAGAMENTO: 'Aguardando pagamento',
      PAGO: 'Pronto para entrega',
      PRONTO_RETIRADA: 'Pronto para retirada',
      EMENTREGA: 'Em entrega',
      ENTREGUE: 'Entregue',
      CANCELADO: 'Cancelado'
    },
    acaoLabel: {
      TRANSACTION: 'Transação',
      CAPTURE: 'Captura',
      CANCEL: 'Cancelamento'
    }
  }),
  created() {
    const idRoute = this.$route.params.id
    this.loadData(idRoute)
  },
  methods: {
    loadData(idRoute) {
      this.$store
        .dispatch(PAGAMENTO_DETALHE, idRoute)
        .then(data => {
          this.numpedido = _.get(data, 'numpedido')
          this.idpagamento = _.get(
            data,
            'pagamento_sucesso.IdPagamento',
            _.get(data, 'pagamento_pendente.IdPagamento')
          )
          this.data_pagamento = moment(_.get(data, 'data_pagamento')).format(
            'DD/MM/YYYY HH:mm'
          )
          this.statuspedido = _.get(this.statusLabel, data.statuspedido, '')
          this.nome_cliente = _.get(data, 'nome_cliente')
          this.total_pedido = `${data.total_liquido}`.replace('.', ',')
          this.total_pago = `${data.total_pago}`.replace('.', ',')
          this.total_estornado = `${data.total_estornado}`.replace('.', ',')
          this.total_para_estorno = `${data.deveria_estornar}`.replace('.', ',')
          const logPagamento = _.get(data, 'logs', []) || []
          this.logs = logPagamento.reduce((logs, log) => {
            const ValorTransacao = _.get(
              log,
              'body.data.DadosResposta.FormaPagamento.ValorTransacao',
              _.get(log, 'body.data.DadosResposta.ValorTransacao', 0)
            )
            const valor = `${(Number(ValorTransacao || 0) / 100).toFixed(
              2
            )}`.replace('.', ',')
            const date = moment(_.get(log, 'date')).format('DD/MM/YYYY HH:mm')
            logs.push({
              Tipo: _.get(log, 'body.data.DadosResposta.Tipo'),
              Sucesso: _.get(log, 'body.data.DadosResposta.Sucesso'),
              Mensagem: _.get(log, 'body.data.DadosResposta.Mensagem', ''),
              Tid: _.get(
                log,
                'body.data.DadosResposta.FormaPagamento.Tid',
                _.get(log, 'body.data.DadosResposta.Tid')
              ),
              Sequential: _.get(
                log,
                'body.data.DadosResposta.FormaPagamento.Sequential',
                _.get(log, 'body.data.DadosResposta.Sequential')
              ),
              AcaoAlias: _.get(
                this.acaoLabel,
                _.get(log, 'body.data.DadosResposta.AcaoAlias', 'TRANSACTION')
              ),
              ValorTransacao: valor,
              date,
              bandeira: _.get(
                log,
                'body.data.DadosResposta.CartaoSalvo.Bandeira',
                _.get(
                  log,
                  'body.data.Requisicao.FormaPagamento.Cartao.Bandeira',
                  ''
                )
              )
            })
            const sublogs = _.get(log, 'body.data.GatewayResponse', []) || []
            sublogs.map(sublog => {
              const alias = _.get(sublog, 'AcaoAlias')
              if (
                alias !==
                _.get(log, 'body.data.DadosResposta.AcaoAlias', 'TRANSACTION')
              ) {
                logs.push({
                  Tipo: _.get(log, 'body.data.DadosResposta.Tipo'),
                  Sucesso: _.get(sublog, 'DadosResposta.Sucesso'),
                  Mensagem: _.get(sublog, 'DadosResposta.Mensagem', ''),
                  Tid: _.get(sublog, 'DadosResposta.Tid'),
                  Sequential: _.get(sublog, 'DadosResposta.Sequential'),
                  AcaoAlias: _.get(this.acaoLabel, _.get(sublog, 'AcaoAlias')),
                  ValorTransacao: valor,
                  date
                })
              }
            })
            return logs
          }, [])
          const logEstorno = _.get(data, 'logs_evento_estorno_cartao', []) || []
          this.logs = logEstorno.reduce((logs, log) => {
            logs.push({
              Tipo: 'CREDITO',
              Sucesso: _.get(
                log,
                'DadosResposta.Sucesso',
                _.get(log, 'Body.DadosResposta.Sucesso')
              ),
              Mensagem: _.get(
                log,
                'DadosResposta.Mensagem',
                _.get(log, 'Body.DadosResposta.Mensagem', '')
              ),
              Tid: _.get(log, 'Body.data.DadosResposta.Tid'),
              Sequential: _.get(log, 'Body.DadosResposta.Sequential'),
              AcaoAlias: _.get(this.acaoLabel, 'CANCEL'),
              ValorTransacao: `${(
                Number(_.get(log, 'Estorno.ValorEstorno', 0), 0) / 100
              ).toFixed(2)}`.replace('.', ','),
              date: moment(_.get(log, 'Estorno.date')).format(
                'DD/MM/YYYY HH:mm'
              )
            })
            return logs
          }, this.logs)
        })
        .catch(err => {
          this.$router.replace('/fornecedor/pagamentos')
          this.$emit('close', err)
        })
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.pay {
  width: 100%;
  .pay-label {
    font-weight: 800;
  }
}
.table {
  width: 100%;
  td {
    min-width: 100px;
    padding: 2px 5px;
  }
}
</style>
